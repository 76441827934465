/*@font-face {*/
/*    font-family: 'FSEmeric' !important;*/
/*    src: url("assets/fonts/FSEmeric.eot") !important;*/
/*    src: url("assets/fonts/FSEmeric.woff") format("woff") !important;*/
/*}*/
@import url(//db.onlinewebfonts.com/c/9c2a59d0a11dfc33e7c45a8da4f232a0?family=FS+Emeric);

@font-face {
  font-family: "FS Emeric";
  src: url("//db.onlinewebfonts.com/t/9c2a59d0a11dfc33e7c45a8da4f232a0.eot");
  src: url("//db.onlinewebfonts.com/t/9c2a59d0a11dfc33e7c45a8da4f232a0.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/9c2a59d0a11dfc33e7c45a8da4f232a0.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/9c2a59d0a11dfc33e7c45a8da4f232a0.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/9c2a59d0a11dfc33e7c45a8da4f232a0.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/9c2a59d0a11dfc33e7c45a8da4f232a0.svg#FS Emeric")
      format("svg");
}

body {
  /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
  font-family: FS Emeric;
}

.dzu-dropzone {
  overflow: hidden;
}

.card-header {
  background-color: #044470;
  color: white;
  border-radius: 0 !important;
}

.modal-header {
  background-color: #044470;
  border-radius: 0 !important;
}

.modal-header h5 {
  color: white !important;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.modal-content {
  border-radius: 0;
}

.card-header h6 {
  color: white;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

label {
  color: #044470;
  font-weight: bold;
  font-size: 1.1rem;
}

.btn-primary {
  background-color: #e5760a;
  border-color: #e5760a;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
}

.card {
  border-radius: 0;
}

.bg-light {
  /*background-color: #ccc !important;*/
  color: #044470 !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: white;
  background-color: #cb660a;
  border-color: #e5760a; /*set the color you want here*/
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgb(227, 227, 227);
}

.ng-blue {
  color: #044470 !important;
  font-weight: bold;
  vertical-align: center !important;
}

tbody {
  text-transform: uppercase;
}

.simulation-results tbody tr td:first-child {
  color: grey;
  font-weight: bold;
}

.simulation-data tbody tr td:first-child {
  color: #044470 !important;
  font-weight: bold;
  vertical-align: center;
}

.main-sidebar .nav-item {
  background-color: #044470;
  color: white !important;
  text-transform: uppercase;
}

.main-sidebar .nav-item a {
  color: white !important;
}

/*.main-sidebar .nav .nav-item:hover {*/
/*  background-color: #043560 !important;*/
/*}*/

.main-sidebar .nav .nav-item .nav-link.active,
.main-sidebar .nav .nav-item .nav-link:hover,
.main-sidebar .nav .nav-item.active,
.main-sidebar .nav .nav-item:hover {
  box-shadow: inset 0.1875rem 0 0 #cb660a;
  color: #043358 !important;
  background-color: white;
}

.main-sidebar .nav .nav-item .nav-link.active i,
.main-sidebar .nav .nav-item .nav-link:hover i,
.main-sidebar .nav .nav-item.active i,
.main-sidebar .nav .nav-item:hover i {
  color: #cb660a;
}

.nav-item .dropdown a {
  margin-top: 20px;
  text-transform: uppercase;
}

.add-elements {
  color: #e5760a;
  font-size: 25px !important;
  /*text-align: right;*/
  float: right;
  cursor: pointer;
}

.upper {
  text-transform: uppercase;
}

input[disabled] {
  background-color: transparent !important;
  border: 0;
  font-size: 1em;
  cursor: default !important;
  color: black;
}

textarea {
  text-transform: uppercase;
}

textarea[disabled] {
  background-color: transparent !important;
  border: 0;
  font-size: 1em;
  cursor: default !important;
  color: black;
}

select {
  text-transform: uppercase;
}

select[disabled] {
  background-color: transparent !important;
  border: 0;
  font-size: 1em;
  cursor: default !important;
  background-size: 0px;
  color: #495057 !important;
}

option {
  text-transform: uppercase;
}
/*.main-content-container {*/
/*  background-image: url("images/fondo_parque_industrial.jpg") !important;*/
/*  background-repeat: no-repeat;*/
/*  background-position: center, center;*/
/*  background-size: 80%;*/
/*}*/

/*.card-header {*/
/*  background-image: url("images/blue-with-vignette-marble-texture-background-with-copy-space_23-2148327728.jpg") !important;*/
/*}*/

/*.card {*/
/*  background: rgba(240, 240, 240, 0.9);*/
/*}*/

.tl-success {
  color: #00bf00;
  text-align: center;
}

.tl-warning {
  color: #f9bd30 !important;
  text-align: center;
}

.tl-danger {
  color: #bf000a;
  text-align: center;
}

.dzu-dropzone {
  overflow: hidden !important;
}

.np {
  padding: 0;
}

.modal-body {
  /*max-height: 350px;*/
  padding: 15px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 767px) {
  .modal-body {
    max-height: 500px;
    padding: 15px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .modal {
    left: 1%;
  }

  .container-max {
    display: flex;
    width: 50px;
  }

  .qr {
    width: 100%;
  }

  .test {
    margin-top: 10px;
    font-family: Arial, sans-serif;
    font-size: 12px;
    color: #000000;
  }

  .p-ver {
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 13px;
    color: #000000;
  }
}

@media (max-width: 575px) {
  .qr {
    width: 28%;
  }
}

@media (min-width: 768px) {
  .modal {
    left: 5%;
  }

  .public-card {
    width: 600px;
  }

  .public-card-download {
    width: 321.25984251968504px;
    height: 200.31496062992125px;
    /* padding: 50px 10px 20px 15px;  */
  }

  .download-card {
    padding: 0;
    position: absolute;
    left: 100px;
    top: -1000px;
  }

  .class-body-one {
    display: none;
  }

  .qr {
    width: 100%;
  }
  .test {
    margin-top: 10px;
    font-family: Arial, sans-serif;
    font-size: 12px;
    color: #000000;
  }
  .p-ver {
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 13px;
    color: #000000;
  }
}

.home-slide {
  background-color: #044470;
  /*background-color: #0479c6;*/
  color: white;
  font-weight: bold;
}

.qr-download {
  width: 60px;
}

.test-download {
  font-family: Arial, sans-serif;
  font-size: 6pt;
  font-weight: 500;
  color: #000000;
  margin-top: -1.8rem;
  line-height: normal;
  padding: 0;
}

.p-ver-download {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 7pt;
  color: #000000;
  padding: 0 1rem;
  margin: 0;
  line-height: normal;
}

.p-ver-download-one {
  padding: 0;
}

.home-slide img {
  width: 26%;
  clear: both;
}

.f-right {
  float: right;
}

.card-header {
  border-bottom: 4px solid #e57200 !important;
}

.modal-header {
  display: block;
  border-bottom: 4px solid #e57200 !important;
}

.user-menu {
  margin-top: 5px;
}

.header-icon {
  float: left;
  font-size: 25px !important;
}

.logo-side {
  width: 80%;
  margin-left: 10px;
}

.input-group-append {
  color: red;
  /*display:none !important;*/
}

.btn-primary:disabled,
.btn-primary[disabled] {
  /*border: 1px solid #999999;*/
  /*background-color: #cccccc;*/
  /*color: #666666;*/
  background-color: #e5760a;
  border-color: #e5760a;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
  opacity: 0.8;
  box-shadow: 0 0 0 0 !important;
}

.btn-primary:active,
.btn-primary[active] {
  /*border: 1px solid #999999;*/
  /*background-color: #cccccc;*/
  /*color: #666666;*/
  background-color: #e5760a;
  border-color: #e5760a;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
  opacity: 0.8;
  box-shadow: 0 0 0 0 !important;
}

.controlRow__root {
  margin-bottom: 5px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-color: #e5760a;
  border-color: #e5760a;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
  opacity: 0.8;
  box-shadow: 0 0 0 0 !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #e5760a;
  border-color: #e5760a;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
  opacity: 0.8;
  box-shadow: 0 0 0 0 !important;
}

table-datatable__root thead {
  color: red !important;
}

.thead {
  color: #044470 !important;
  background-color: #fbfbfb !important;
  font-weight: bold;
}

th {
  font-size: 1.1rem;
}

.top-em {
  border-top: 2px solid #898d91;
}

.note {
  font-weight: bolder;
  margin-bottom: 10px;
  font-size: 16px;
}

pre {
  font-family: inherit;
}

.user-menu {
  margin-top: 5px;
}

.test-one {
  position: relative;
  right: 7px;
}

.no-resize {
  resize: none;
}

.tl-success {
  color: #00bf00;
  text-align: center;
}

.tl-warning {
  color: #bfbb15;
  text-align: center;
}

.tl-danger {
  color: #bf000a;
  text-align: center;
}

.profile-img {
  width: 100%;
  height: auto;
  padding: 5%;
}

.image {
  /*border-radius: 50%;*/
  /*position:relative;*/
  /*overflow:hidden;*/
  /*padding-bottom:100%;*/
}
.image img {
  border-radius: 50%;
  /*position:absolute;*/
}

.blue {
  background-color: #044470;
  color: white;
  margin-left: -30px;
  margin-right: -30px;
}

.image-scale {
  width: 100%;
}

.img-responsive {
  background-color: none;
  width: 130px;
}

.img-responsive-download {
  background-color: none;
  width: 125px;
  margin-left: -3.5rem;
  margin-top: .1rem;
}

.personnel-data {
  margin-top: 20px;
  font-size: 20px;
  margin-left: 20px;
  color: #044470;
}

.class-body {
  padding: 15% 30px 0px 30px;
}

.class-body-download {
  padding: 50px 26.456696247px 0px 26.456696247px;
}

.personnel-legend {
  /*color: #044470;*/
  color: #044470;
  margin-top: 30px;
  text-align: justify;
}

.personnel-legend p {
  margin-bottom: 5px;
}

.bold {
  font-weight: bold;
  font-size: 20px;
}

.color-black {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #000000;
}

.color-black-download {
  font-family: Arial, sans-serif;
  font-size: 9pt;
  color: #000000;
}

.color-black-1 {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #000000;
  text-align: center;
  position: absolute;
  left: 123px;
}

.color-black-1-download {
  font-family: Arial, sans-serif;
  font-size: 9pt;
  color: #000000;
  text-align: center;
  position: absolute;
  left: 90px;
}

/* .download-card {
  position: absolute;
  left: 100px;
  top: -800px;
} */

.color-orange {
  font-family: Arial, sans-serif;
  color: #e5760a;
  font-size: 24px;
  font-weight: bold;
}

.color-danger {
  font-family: Arial, sans-serif;
  color: rgb(251, 8, 8);
  font-size: 24px;
  font-weight: bold;
}

.color-orange-label {
  margin-right: -100px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #e5760a;
}

.color-orange-label-download {
  margin-right: -120px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 8pt;
  color: #e5760a;
}

.color-orange-download {
  font-family: Arial, sans-serif;
  color: #e5760a;
  font-size: 8pt;
  font-weight: bold;
}

.color-danger-download {
  font-family: Arial, sans-serif;
  color: rgb(251, 8, 8);
  font-size: 8pt;
  font-weight: bold;
}

.data-personnel {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
}
.personnel-title {
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 30px;
  margin-top: -30px;
  margin-bottom: 0px;
  font-weight: bold;
  text-align: center;
  color: #000000;
}

.personnel-title-company {
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 18px;
  margin-top: -0px;
  margin-bottom: 0px;
  /* font-weight: bold; */
  text-align: center;
  color: #000000;
}

.personnel-title-company-one {
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 22px;
  margin-top: -20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
  color: #000000;
}

.personnel-title-download-container {
  margin-bottom: -12px;
}
.personnel-title-download {
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 9pt;
  line-height: normal;
  margin-top: -42px;
  margin-bottom: 0px;
  font-weight: bold;
  text-align: center;
  color: #000000;
}

.personnel-data .row .col:nth-child(2) {
  margin-left: -0px;
}

.data-personnel-label {
  margin-right: -100px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
}

.data-personnel-download {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 7pt;
  color: #000000;
  line-height: 0.8rem;
}

.data-personnel-label-download {
  margin-right: -120px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 7pt;
  line-height: normal;
  color: #000000;
}

.personnel-data {
  width: 300px;
  /* padding: 10px 10px 10px 10px;  */
}

.personnel-data-download {
  width: 200px;
}

.personnel-img {
  width: 135px;
  height: 160px;
}

.personnel-img-download-container {
  padding: 0 0;
  max-height: 8rem;
}

.personnel-img-download {
  width: 100%;
  height: 100%;
}

.pagination-centered {
  width: 200px;
  height: 90px;
}

.container-div {
  display: flex;
  margin-left: 0px;
  margin-bottom: 15px;
}

.name-company {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #000000;
}

.name-company-download {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #000000;
}

.logo-company {
  max-width: 100%;
  max-height: 75%;
}

.logo-company-1 {
  display: flex;
  justify-content: center;
}

.row-company {
  margin-top: 8%;
  justify-content: center;
}

.row-title {
  margin-top: 5%;
}

.container-max {
  display: flex;
}

.profile {
  width: 50%;
  margin-left: 80px;
  margin-top: 6px;
}

.personnel-detail-form label {
  /*background-color: red;*/
  font-size: 20px;
}

.btn-danger {
  background-color: #e50a0a;
  border-color: #e50a0a;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
}
